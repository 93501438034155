:root {
  --font: montserrat, sans-serif;
  --main-background: #fff;
  --main-border: #c1c5cd;
  --main-template-background: #f5f5f5;
  --main-border-radius: 8px;
}

html,
body {
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box !important;
}

body {
  position: relative;

  overflow-x: hidden;
  overflow-y: hidden;

  margin: 0;

  font-family: var(--font);
  text-rendering: optimizespeed;
}

#root {
  position: relative;

  overflow-x: hidden;
  overflow-y: hidden;

  width: 100vw;
  min-width: 100%;
  min-height: 100%;

  text-size-adjust: none;

  -webkit-overflow-scrolling: touch;
}

h1,
h2,
h3,
p,
label {
  margin: 0;
  font-family: var(--font);
}

input,
select {
  font-family: inherit;
  font-size: inherit;
}

a {
  text-decoration: none;
}

a,
button {
  cursor: pointer;
}

input[type='checkbox'],
input[type='radio'] {
  margin: 0 !important;
}

table {
  border-spacing: 0;
  border-color: unset;
}

th,
td {
  border: none;
}

iframe#webpack-dev-server-client-overlay {
  left: 50px !important;
  width: 200px !important;
  height: 30vh !important;
  margin-top: 70vh;
}
